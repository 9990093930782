import api from './api';
import TokenService from './token.service';

const AllocateService = {
    newAllocate: async (user_id, locals) => {
        try {
          const response = await api
          .post(`/adm/local_user/${user_id}/`, {
            locals
          }, {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
          });
  
          if (response.status === 200) {
            return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },

    getAllocatsUser: async (user_id) => {
      try {
        const response = await api
        .get(`/adm/local_user/${user_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
          return response;
        } 

      } catch (error) {
        return error.response?.data;
      }
    },
}

export default AllocateService;