import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Layout'; // Importando o novo Layout
import { Login } from './login/acesso';
import { EsqueciSenha } from './login/esqueci_senha';
import Home from './view/home';
import UserList from './view/user';
import Profile from './view/user/profile';
import EditUser from './view/user/edit_user';
import LocationsList from './view/locations';
import FrequencyList from './view/frequency';
import MeasureList from './view/measure';
import AllocateList from './view/allocate';
import EditLocation from './view/locations/edit_location';
import EditFrequency from './view/frequency/edit_frequency';
import EditMeasure from './view/measure/edit';
import FlowchartList from './view/flowchart';
import Editor from './view/flowchart/editor';
import EditFlowchart from './view/flowchart/edit_flowchart';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><Home /></Layout>
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/esquecisenha",
    element: <EsqueciSenha />
  },
  {
    path: "/usuarios",
    element: <Layout><UserList /></Layout>
  },
  {
    path: "/perfil",
    element: <Layout><Profile /></Layout>
  },
  {
    path: "/usuarios/:id",
    element: <Layout><EditUser /></Layout>
  },
  {
    path: "/locais",
    element: <Layout><LocationsList /></Layout>
  },
  {
    path: "/locais/:id",
    element: <Layout><EditLocation /></Layout>
  },
  {
    path: "/frequencias",
    element: <Layout><FrequencyList /></Layout>
  },
  {
    path: "/frequencias/:id",
    element: <Layout><EditFrequency /></Layout>
  },
  {
    path: "/unidades",
    element: <Layout><MeasureList /></Layout>
  },
  {
    path: "/unidades/:id",
    element: <Layout><EditMeasure /></Layout>
  },
  {
    path: "/alocacoes",
    element: <Layout><AllocateList /></Layout>
  },
  {
    path: "/processos",
    element: <Layout><FlowchartList /></Layout>
  },
  {
    path: "/processos/:id",
    element: <Layout><EditFlowchart /></Layout>
  },
  {
    path: "/processos/editor/:id",
    element: <Layout><Editor /></Layout>
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
