import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import "./index.scss";
import Convert from '../../../../../../utils/convert';
import CardUp from '../../../../../../components/icons/card_up';
import CardDown from '../../../../../../components/icons/card_down';
import EditIcon from '../../../../../../components/icons/edit';
import FrequencyService from '../../../../../../services/frequency';
import MeasurementService from '../../../../../../services/measurement';


const CardList = ({ element_id, refresh}) => {
  const [dataCard, setDataCard] = useState([]);
  const [measures, setDataMeasures] = useState([]);
  const [frequencys, setDataFrequencys] = useState([]);

  const getProperties = () => {
    setDataCard('')
    const savedData = localStorage.getItem('properties');
  
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setDataCard(parsedData);
    }
  }

  useEffect(() => {
    getProperties();

    const fetchLocations = async () => {
      const measure = await MeasurementService.getMeasurements();
      if (measure) {
        let measureSelect = measure.data.data;
        let transformedMeasure = measureSelect.map(measure => ({
          label: measure.name,
          value: measure.measurement_id,
        }));
        setDataMeasures(transformedMeasure)
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };

    fetchLocations();

    const fetchUsers = async () => {
        const frequency = await FrequencyService.getFrequencys();
        if (frequency) {
          let frequencySelect = frequency.data.data;
          let transformedFrequency = frequencySelect.map(frequency => ({
            label: frequency.name,
            value: frequency.frequency_id,
          }));
          setDataFrequencys(transformedFrequency)
        } else {
          console.log('Não foi possível obter os usuários.');
        }
      };

    fetchUsers();
  }, [element_id]);

  useEffect(() => {
    getProperties();
  }, [refresh]);

  

  const getMeasurementName = (value) => {
    const measurement_name = measures.find(item => item.value === value);
    return measurement_name ? measurement_name.label : 'Desconhecido'; // Retorna o label ou 'Desconhecido' se não encontrar
  };
  
  const getFrequencyName = (value) => {
    const frequency_name = frequencys.find(item => item.value === value);
    return frequency_name ? frequency_name.label : 'Desconhecido'; // Retorna o label ou 'Desconhecido' se não encontrar
  };

  return (
    <div className="container_cards">
      {dataCard.length > 0 ? (
        dataCard.map((item, index) => {
          // Pegando o label correspondente ao value do item atual
          const labelMeasurement = getMeasurementName(item.measurement_id);
          const labelFrequency = getFrequencyName(item.frequency_id);

          return (
            <Card key={index} data={{ ...item, measurement_name: labelMeasurement,  frequency_name: labelFrequency}} />
          );
        })
      ) : (
        <p>Nenhuma propriedade disponível ou encontrada.</p>
      )}
    </div>
  );
};

// Componente para renderizar cada card individualmente
const Card = ({ data }) => {
  const {
    property_id,
    name,
    description,
    min_value,
    max_value,
    lower_specification,
    upper_specification,
    measurement_name,
    frequency_name,
    data_type,
    active
  } = data;

  const [isExpanded, setIsExpanded] = useState(false);

  // Função para alternar a expansão do card
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3>{name}</h3>
        <div className='card-header-actions'>
            {property_id ? <EditIcon /> : ""}
            <div onClick={toggleExpand}>{!isExpanded ? <CardDown /> : <CardUp />}</div>
        </div>
      </div>
      {isExpanded && (
        <div className="card-body">
            <p>{description}</p>
            <p><strong>Valor minímo:</strong> {min_value}</p>
            <p><strong>Valor máximo:</strong> {max_value}</p>
            <p><strong>Especificação inferior:</strong> {lower_specification}</p>
            <p><strong>Especificação superior:</strong> {upper_specification}</p>
            <p><strong>Unidades de medidas:</strong> {measurement_name}</p> 
            <p><strong>Frequências:</strong> {frequency_name}</p>
            <p><strong>Data Type:</strong> {Convert.convertOptionsDataType(data_type)}</p>
            <p><strong>Ativo:</strong>  <Badge bg={active ? "success" : "danger"}>{active ? "Ativo" : "Desativado"}</Badge></p>
        </div>
      )}
    </div>
  );
};

export default CardList;
