import React, { useCallback, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../../components/breadcrumbs';
import { Alert, Button, Container, Modal, Row } from 'react-bootstrap';
import "./index.scss";
import BpmnModeler from "bpmn-js/lib/Modeler";

import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import CustomContextPadModule from "./context-pad";
import CustomPalette from './palette';
import "./index.scss";
import EditProperties from './element';
import AuthService from '../../../services/auth.service';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import FlowchartService from '../../../services/flowchart';
import Convert from '../../../utils/convert';

const Editor = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const canvasRef = useRef(null);
  const modelerRef = useRef(null);
  const [showEditProperties, setShowEditProperties] = useState(false);
  const [dataFlow, setDataFlow] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [elementId, setElementId] = useState('');
  const [elementType, setElementType] = useState('');
 
  const [name, setName] = useState('');
  const [xml, setXml] = useState('');

  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  const handleShow = () => setShowEditProperties(true);
  const handleClose = () => setShowEditProperties(false);

  const [showModal, setShowModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false); // Novo estado

  

  // Função para detectar mudanças no XML
  const detectChanges = useCallback(async () => {
    if (modelerRef.current) {
      const { xml: currentXml } = await modelerRef.current.saveXML();
      if (xml !== currentXml) {
        setHasChanges(true);
        console.log('teste alteracao xml')
      } else {
        setHasChanges(false);
        console.log('teste alteracao inicio')
      }
    }
  }, [xml]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = ""; // required for chrome
        return true; // Return a truthy value
      }
      return null; // Allow navigation if no conditions met
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasChanges]);

  const shouldBlockNavigation = () => {
    if (hasChanges) {
      setShowModal(true);
      return "Are you sure you want to leave? You may lose unsaved changes.";
    }
    return null; // Allow navigation
  };

  let blocker = useBlocker(shouldBlockNavigation);

  useEffect(() => {
    localStorage.removeItem("removeItems");
    localStorage.removeItem("connectItems");
    
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();

    const storedProps = localStorage.getItem('flowchart');

    if (storedProps) {
        setDataFlow(JSON.parse(storedProps));
    }
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate, id]);


  useEffect(() => {
    const handleSearch = () => {
      if (dataFlow && dataFlow.length > 0) {
        const result = dataFlow.find(item => item.flowchart_id === parseInt(id));
        setName(result.name);
        setXml(result.xml);
      }
    };

    handleSearch();

  }, [id, dataFlow]);

  useEffect(() => {
    if (!canvasRef.current) {
      console.error('canvasRef.current não está definido');
      return;
    }

    modelerRef.current = new BpmnModeler({
      container: canvasRef.current,
      additionalModules: [
        CustomContextPadModule,
        CustomPalette
      ]
    });

    if (!modelerRef.current) {
      console.error('modelerRef.current não está definido');
      return;
    }

    // Função para carregar ou criar o diagrama
    const loadDiagram = async () => {
      if (!modelerRef.current) {
        console.error('modelerRef.current não está definido');
        return;
      }

      try {
        if (!xml) {
          await modelerRef.current.createDiagram();
          console.log('Diagrama criado com sucesso!');
        } else {
          await modelerRef.current.importXML(xml);
          console.log('Diagrama carregado com sucesso!');
        }
      } catch (err) {
        console.error('Erro ao carregar ou criar o diagrama:', err);
      }
    };

    loadDiagram();

    const eventBus = modelerRef.current.get('eventBus');

    const handleElementClick = (event) => {
      const { element } = event;
      if (element.type === 'bpmn:Task' || element.type === "bpmn:StartEvent" || element.type === "bpmn:EndEvent") {
        let taskName = element.businessObject || 'No name provided';
        if(taskName.name) {
          setTaskName(taskName.name);
          setElementId(taskName.id);
          setElementType(taskName.$type);
          handleShow(); 
        }     
      }
    };
    
    eventBus.on('element.click', handleElementClick);

          // Detectar mudanças no XML
    eventBus.on('commandStack.changed', detectChanges);

    return () => {
      if (eventBus) {
        eventBus.off('element.click', handleElementClick);
      }
      if (modelerRef.current) {
        modelerRef.current.destroy();
      }
    };


  }, [xml, detectChanges]);

  const fetchFlowchartElement =  useCallback(async () => {
    try {
      const flowchartElement = await FlowchartService.getFlowchartElement(id);
      if (flowchartElement) {
        let flowchartElements = flowchartElement.data.data;
        if (flowchartElements) {
            localStorage.setItem('flowchartElements', JSON.stringify(flowchartElements.elements));
            localStorage.setItem('flowchartConnects', JSON.stringify(flowchartElements.connections));
        }
      } else {
        setErros('Não foi possível obter os elementos.');
      }
    } catch (error) {
      console.error("Não foi possível obter os elementos.", error);
    }
  }, [id]);
  
  useEffect(() => {
    fetchFlowchartElement();
  },[fetchFlowchartElement]);

  const handleEdit = (xml) => {
    const updatedData = dataFlow.map(item => {
      if (item.flowchart_id === parseInt(id)) {
        return { ...item, xml: xml }; // Atualize o campo desejado aqui
      }
      return item;
    });

    // Salve o array atualizado de volta no localStorage
    localStorage.setItem("flowchart", JSON.stringify(updatedData));
    setDataFlow(updatedData); // Atualize o estado local
  };

  const saveNewElement = async (process) => {
    const storedElements = JSON.parse(localStorage.getItem('flowchartElements')) || [];

    if(process.length > 0) {
      const newElements = process.filter(el => 
          !storedElements.some(storedEl => storedEl.extra_data.element_prid === el.id) 
      );

      if (newElements.length > 0) {
          try {
            // Itera sobre cada elemento a ser salvo
              for (const element of newElements) {
                let element_prid = {
                  "element_prid": element.id,
                }
                let element_type = Convert.convertTypeElement(element.type);
                let element_name = element.businessObject.name;
                let element_properties = [];
                if(element_name.trim() > 0) {
                  try {
                    const response = await FlowchartService.newElement(
                        parseInt(id),
                        element_prid,
                        null,
                        element_name,
                        element_type, 
                        element_properties
                    );
            
                    if (response.status === 200) {
                        setErros({});
                    } else {
                        erros.submit = response.message;
                        setAlert(true);
                    }
                  } catch (err) {
                      erros.submit('Erro ao salvar o elemento:', err);
                  }
                }
              }
          } catch (error) {
            console.error('Erro ao fazer a requisição para salvar os elementos:', error);
          }

          console.log('Novos elementos salvos:', newElements);
      } else {
          console.log('Nenhum novo elemento encontrado.');
      }
    }
  };

  const saveNewConnect = async (connections) => {
    const storedConnects = JSON.parse(localStorage.getItem('flowchartConnects')) || [];
    const storedElements = JSON.parse(localStorage.getItem('flowchartElements')) || [];

    if(connections.length > 0) {
      const newElements = connections.filter(el => 
          !storedConnects.some(storedEl => storedEl.extra_data.element_prid === el.id) 
      );

      if (newElements.length > 0) {
          try {
            // Itera sobre cada elemento a ser salvo
              for (const element of newElements) {
                let element_prid = {
                  "element_prid": element.id,
                }
                let id_start = storedElements.find(el => el.extra_data.element_prid === element.source.id);
                let id_target = storedElements.find(el => el.extra_data.element_prid === element.target.id);

                let connections_exists = storedConnects.find(el => el.origin_id === id_start.element_id  && el.destiny_id === id_target.element_id);
                console.log('connections_exists: ', connections_exists)
                if(!connections_exists) {
                  try {
                    const response = await FlowchartService.newConnect(
                        parseInt(id),
                        parseInt(id_start.element_id),
                        parseInt(id_target.element_id),
                        element_prid
                    );
            
                    if (response.status === 200) {
                        setErros({});
                    } else {
                        erros.submit = response.message;
                        setAlert(true);
                    }
                  } catch (err) {
                      erros.submit('Erro ao salvar o elemento:', err);
                  }
                } else {
                  try {
                    const response = await FlowchartService.updateConnect(
                        parseInt(connections_exists.flowchart_id),
                        parseInt(connections_exists.connection_id),
                        true,
                        element_prid
                    );
            
                    if (response.status === 200) {
                        setErros({});
                    } else {
                        erros.submit = response.message;
                        setAlert(true);
                    }
                  } catch (err) {
                      erros.submit('Erro ao salvar o elemento:', err);
                  }
                }
              }
          } catch (error) {
            console.error('Erro ao fazer a requisição para salvar os elementos:', error);
          }
      } else {
          console.log('Nenhum novo elemento encontrado.');
      }
    }
  };

  const updateActiveStatus = async () => {
    // Obtenha o array de elementos do localStorage (substitua 'yourKey' pela chave correta)
    const elementsRemove = JSON.parse(localStorage.getItem('removeItems')) || [];
    const elements = JSON.parse(localStorage.getItem('flowchartElements')) || [];
    const connections = JSON.parse(localStorage.getItem('flowchartConnects')) || [];
  
    // Percorre o array de elementos do localStorage e compara com o element_prid
    const updatedElementsData = elements.map(item => {
      const matchingElement = elementsRemove.find(el => el.id === item.extra_data.element_prid);
  
      // Se houver correspondência, altera o campo active para false
      if (matchingElement) {
        return { ...item, active: false };
      }
  
      return item;
    });

    const updatedConnectionsData = connections.map(itemc => {
      const matchingConnections = elementsRemove.find(el => el.id === itemc.extra_data.element_prid);

      if (matchingConnections) {
        return { ...itemc, active: false};
      }
  
      return itemc;
    });
    
    console.log('updatedConnectionsData: ', updatedConnectionsData);
    // Salva os elementos atualizados no localStorage ou outra fonte de dados
    localStorage.setItem('flowchartElements', JSON.stringify(updatedElementsData));
    localStorage.setItem('flowchartConnects', JSON.stringify(updatedConnectionsData));

    const elementsToSave = updatedElementsData.filter(item => !item.active);
    const connectionsToSave = updatedConnectionsData.filter(item => !item.active);

    console.log('connectionsToSave: ', connectionsToSave);

    // Se houver elementos com active: false, envie-os ao banco de dados
    if (elementsToSave.length > 0) {
      try {
        // Itera sobre cada elemento a ser salvo
          for (const element of elementsToSave) {
            try {
              const response = await FlowchartService.newElement(
                  parseInt(element.flowchart_id),
                  element.extra_data,
                  parseInt(element.element_id),
                  element.name,
                  element.element_type,
                  element.properties,
                  element.description,
                  element.active,
                  element.extra_data,

              );
      
              if (response.status === 200) {
                  setErros({});
              } else {
                  erros.submit = response.message;
                  setAlert(true);
              }
            } catch (err) {
                erros.submit('Erro ao salvar o elemento:', err);
            }
          }
      } catch (error) {
        console.error('Erro ao fazer a requisição para salvar os elementos:', error);
      }
    }

    if (connectionsToSave.length > 0) {
      try {
        // Itera sobre cada elemento a ser salvo
          for (const connect of connectionsToSave) {
            try {
              const response = await FlowchartService.updateConnect(
                  parseInt(connect.flowchart_id),
                  parseInt(connect.connection_id),
                  connect.active,
              );
      
              if (response.status === 200) {
                  setErros({});
              } else {
                  erros.submit = response.message;
                  setAlert(true);
              }
            } catch (err) {
                erros.submit('Erro ao salvar o elemento:', err);
            }
          }
      } catch (error) {
        console.error('Erro ao fazer a requisição para salvar os elementos:', error);
      }
    }
  };

  const handleSaveProcess = async () => {
    const elementRegistry = modelerRef.current.get('elementRegistry');
    const connections = elementRegistry.filter((element) => element.type === 'bpmn:SequenceFlow');
    const process = elementRegistry.filter((element) => element.type === 'bpmn:Task' || element.type === "bpmn:StartEvent" || element.type === "bpmn:EndEvent");

    setAlert(false);
    let erros = {};
    if (modelerRef.current) {
      try {
        const xmlsave  = await modelerRef.current.saveXML();
        const response = await FlowchartService.updateFlowchartEdit(
          parseInt(id),
          String(xmlsave.xml)
        );

        if (response.status === 200) {
           setAlert(true);
           handleEdit(xmlsave.xml);
           updateActiveStatus();
           saveNewElement(process);
           saveNewConnect(connections);
           setHasChanges(false);
        } else {
          erros.submit = response.message;
        }
      } catch (err) {
        console.error('Erro ao salvar o diagrama:', err);
      }
    }

    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }

    await fetchFlowchartElement();
  };

  return (
    <Container className='container-custom-fluid'>
      <Row >
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>{name}</h1>
          </div>
        </header>
        <div ref={canvasRef} className="container-editor"  />

        {roleUser.trim() && roleUser === "EDITOR" && (
          <>
            <div className='container-alerts container_alerts_edit'>
              {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Processo salvo com sucesso!</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
            </div>
            <div className='container-panel-button save_process'>
              <Button variant="primary" onClick={handleSaveProcess}>
                Salvar processo
              </Button>
            </div>
            <EditProperties show={showEditProperties} handleClose={handleClose} nameTask={taskName} element_id={elementId} type={elementType}/>
          </>
        )}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alterações não salvas</Modal.Title>
        </Modal.Header>
        <Modal.Body>Você tem alterações não salvas. Deseja salvar antes de sair?</Modal.Body>
        <Modal.Footer>
          <Button variant="outiline-secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="outline-danger" onClick={()=>{blocker?.proceed()}}>
            Sair sem salvar
          </Button>
          <Button variant="primary" onClick={handleSaveProcess}>
            Salvar alterações
          </Button>

        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Editor;
