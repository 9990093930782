import "./index.scss";

const ExchangeIcon = () => {
    return (
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrows-exchange">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 10h14l-4 -4" />
                <path d="M17 14h-14l4 4" />
            </svg>
        </div>
    );
};

export default ExchangeIcon;
