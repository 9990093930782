import api from './api';
import TokenService from './token.service';

const AuthService = {
  login: async (email: string, password: string) => {
    const response = await api
      .post('/accounts/login/', {
        email,
        password
      });
    if (response.data) {
      TokenService.setUser(response.data.data.token);
    }
    return response.data;
  },

  logout: async () => {
    try {
        const response = await api
        .post('/accounts/logout/', {}, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
          console.log('Logout bem-sucedido!');
          TokenService.removeUser();
          return response.status;
        } else {
          return response.status;
        }
    } catch (error) {
      return error;
    }
  },

  verifyRole: async () => {
    const token = TokenService.getUser();

    if (!token) {
        console.error('Token não encontrado.');
        return;
    }

    try {
        const response = await api.get('/accounts/roles/', {
            headers: {
                'Authorization': `Token ${token}`,
            }
        });

        return(response);
    } catch (error) {
        if (error.response) {
            return(error.response.status);
        } else if (error.request) {
            return(error.request);
        } else {
            return(error.message);
        }
    }
  },
  
  getCurrentUser: () => {
    return TokenService.getUser();
  }
};

export default AuthService;
