import api from './api';
import TokenService from './token.service';

const MeasurementService = {
    getMeasurements: async () => {
        try {
            const response = await api
            .get('/adm/measurement/', {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },
    newMeasurement: async (name: string, symbol: string) => {
        try {
          const response = await api
          .post('/adm/measurement/', {
            name,
            symbol
          }, {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
          });
  
          if (response.status === 200) {
            return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    EditMeasurement: async (measurement_id, activate: boolean) => {
      try {
        const response = await api
        .put(`/adm/measurement/${measurement_id}/`, {
          activate
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });
  
        if (response.status === 200) {
          return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },

};

export default MeasurementService;