import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Form, Alert} from 'react-bootstrap';
import CustomInput from '../../../components/input';
import UserService from '../../../services/user';
import Validate from '../../../utils/validate';
import Breadcrumbs from '../../../components/breadcrumbs';
import AllocateService from '../../../services/allocate';
import DataTable from 'react-data-table-component';


const EditUser = () => {
    const navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    const { id } = useParams(); 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [birth_date, setBirthDate] = useState('');
    const [admission_date, setAdmissionDate] = useState('');
    const [selectedRoleOption, setSelectedRoleOption] = useState('');
    const [successRole, setsuccessRole] = useState(false);
    const [successUser, setsuccessUser] = useState(false);
    const [erros, setErros] = useState({});
    const [roleUser, setRoleUser] = useState('');
    const [dataLocations, setDataLocations] = useState([]);
    
    useEffect(() => {
        const fetchRole = async () => {
          try {
            const role = await AuthService.verifyRole();
            if (role.status === 200) {
              const level = role.data.data.roles[0];
              setRoleUser(level);
            } else {
              navigate('/login');
            }
          } catch (error) {
            console.error("Failed to fetch role:", error);
            navigate('/login');
          }
        };
    
        fetchRole();
    }, [navigate]);

    useEffect(() => {
        if (currentUser && roleUser) {
          if (roleUser.trim() && roleUser !== "MASTER") {
            navigate('/');
          }
        } else if (!currentUser) {
          navigate('/login');
        } else {
            const fetchUsers = async () => {
                const user = await UserService.User(id);
                if (user.status === 200) {
                    setName(user.data.data.name);
                    setEmail(user.data.data.email);
                    setBirthDate(user.data.data.birth_date);
                    setAdmissionDate(user.data.data.admission_date);
                    setSelectedRoleOption(user.data.data.roles[0]);
                } else {
                    navigate('/');
                }
              };
    
            fetchUsers();

            const fetchLocations = async () => {
                const allocations = await AllocateService.getAllocatsUser(id);
                if(allocations.status === 200) {
                    setDataLocations(allocations.data.data)
                } 
            };

            fetchLocations();
        }
    }, [roleUser, currentUser, navigate, id]);


    const handleEditRole = async () => {
        try {
            const response = await UserService.editUserRole(
                id, 
                selectedRoleOption
          );
          if (response.data) {
            setsuccessRole(true);
          } 
        } catch (error) {
            // Outro erro
            //setErrorAPI(`Erro ao fazer login: ${error.message}`);
            //seterroAPIShow(true);
        }
    }

    const handleEditUser = async () => {
        let valid = true;
        let erros = {};

        if (!name.trim()) {
            erros.name = 'O nome é obrigatório';
            valid = false;
        }

        if (!email.trim()) {
            erros.email = 'O e-mail é obrigatório';
            valid = false;
        } else if (!Validate.validateEmail(email)) {
            erros.email = 'O e-mail não é válido';
            valid = false;
        }

        if (valid) {
            try {
                const response = await UserService.editUser(
                    id, 
                    name,
                    email,
                    birth_date,
                    admission_date
                );

                if (response.status === 200) {
                    setsuccessUser(true);
                }  else {

                }
            } catch (error) {

            }
        } else {
            setErros(erros);
        }
    }

    const columns = [
        {
          name: 'ID',
          selector: row => row.local_id,
          sortable: true,
          width: "100px",
        },
        {
          name: 'Nome',
          selector: row => row.local_name,
          sortable: true,
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Locais por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
  
    return (
      <div>
        <Container className='container-custom-fluid'>
            <Row>
                <header className='header'>
                    <div>
                        <Breadcrumbs />
                        <h1>{name}</h1>
                    </div>
                </header>
                <section>
                    <div className='container-panel'>
                        <h2>Dados gerais</h2>
                        <div className='container-panel-input'>
                            <div>
                                <CustomInput
                                    label="Nome"
                                    type="text"
                                    value={name}
                                    onChange= {(e) => setName(e.target.value)}
                                />
                                {erros.name && <span className='texterror'>{erros.name}</span>}
                            </div>
                            <div>
                                <CustomInput
                                    label="E-mail"
                                    type="text"
                                    placeholder=""
                                    value={email}
                                    onChange= {(e) => setEmail(e.target.value)}
                                />
                                {erros.email && <span className='texterror'>{erros.email}</span>}
                            </div>
                        </div>
                        <div className='container-panel-date'>
                            <div>
                                <CustomInput
                                    label="Data de nascimento"
                                    type="date"
                                    placeholder=""
                                    value={birth_date}
                                    onChange= {(e) => setBirthDate(e.target.value)}
                                />
                                {erros.birth_date && <span className='texterror'>{erros.birth_date}</span>}
                            </div>
                            <div>
                                <CustomInput
                                    label="Data de admissão"
                                    type="date"
                                    placeholder=""
                                    value={admission_date}
                                    onChange= {(e) => setAdmissionDate(e.target.value)}
                                />
                                {erros.admission_date && <span className='texterror'>{erros.admission_date}</span>}
                            </div>
                        </div>
                        <div className='container-alerts'>
                            {successUser &&  <Alert variant="success" onClose={() => setsuccessUser(false)} dismissible show={successUser}>Dados de usuários alterados com sucesso!</Alert>}
                        </div>
                        {roleUser.trim() && roleUser === "MASTER" && (
                            <div className='container-panel-button' onClick={handleEditUser}>
                                <Button variant="primary">
                                    Salvar alterações
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className='container-panel'>
                        <h2>Níveis de acesso</h2>
                        <div className='container-panel-input'>
                            <Form.Check 
                                type="radio"
                                label="Master"
                                name="radioRoleOptions"
                                value="MASTER"
                                checked={selectedRoleOption === 'MASTER'}
                                onChange= {(e) => setSelectedRoleOption(e.target.value)}
                            />
                            <Form.Check 
                                type="radio"
                                label="Editor"
                                name="radioRoleOptions"
                                value="EDITOR"
                                checked={selectedRoleOption === 'EDITOR'}
                                onChange= {(e) => setSelectedRoleOption(e.target.value)}
                            />
                            <Form.Check 
                                type="radio"
                                label="Operador"
                                name="radioRoleOptions"
                                value="OPERATOR"
                                checked={selectedRoleOption === 'OPERATOR'}
                                onChange= {(e) => setSelectedRoleOption(e.target.value)}
                            />
                            <Form.Check 
                                type="radio"
                                label="Analista"
                                name="radioRoleOptions"
                                value="ANALYST"
                                checked={selectedRoleOption === 'ANALYST'}
                                onChange= {(e) => setSelectedRoleOption(e.target.value)}
                            />
                        </div>
                        <div className='container-alerts'>
                            {successRole &&  <Alert variant="success" onClose={() => setsuccessRole(false)} dismissible show={successRole}>Nível de acesso alterado com sucesso!</Alert>}
                        </div>
                        {roleUser.trim() && roleUser === "MASTER" && (
                            <div className='container-panel-button'>
                                <Button variant="primary" onClick={handleEditRole}>
                                    Alterar nível de acesso
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className='container-panel'>
                        <h2>Locais Atribuídos</h2>
                        <DataTable
                            columns={columns}
                            data={dataLocations}
                            pagination
                            responsive
                            striped
                            paginationComponentOptions={paginationComponentOptions}
                            noDataComponent={<div>Nenhum local atribuído a esse usuário</div>}
                        />
                    </div>
                </section>
            </Row>
        </Container>
      </div>
    );
  };
  
  export default EditUser;