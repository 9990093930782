const Convert = {
    convertDateFormat: (date) => {
        if(date && date.trim()) {
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        } else {
            return '';
        }
    },
    convertRoles: (roles) => {
        const role = roles[0];
        const roleName = {
            'MASTER': 'Master',
            'EDITOR': 'Editor',
            'OPERATOR': 'Operador',
            'ANALYST': 'Analista',
        };

        if (Object.keys(roleName).includes(role)) {
            return roleName[role]; 
        } else {
            return ""; 
        }
    }, 
    convertBreadcrumbs: (item) => {
        const pathToNameMap = {
            'usuarios': 'usuários',
            'frequencias': 'frequências de horário',
            'unidades': 'unidades de medida',
            "alocacoes": 'alocações'
        };

        if (Object.keys(pathToNameMap).includes(item)) {
            return pathToNameMap[item]; 
        } else {
            return item; 
        }
    },
    convertDataBR(dataISO) {
        const data = new Date(dataISO);

        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11
        const ano = data.getFullYear();

        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        //const segundos = String(data.getSeconds()).padStart(2, '0');
    
        // Concatenar e retornar a data e hora formatadas
        return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
    },
    convertOptionsDataType: (type) => {
        const typeData = {
            'BOOL': 'Booleano ',
            'DATE': 'Data',
            'DATETIME': 'Data e hora',
            'NUMBER': 'Número',
            'INTEGER': 'Número inteiros',
            'TEXT': 'Texto',
        };

        if (Object.keys(typeData).includes(type)) {
            return typeData[type]; 
        } else {
            return ""; 
        }
    },
    convertTypeElement: (type) => {
        const typeElement = {
            'bpmn:Task': 'PROCESS',
            'bpmn:StartEvent': 'INPUT',
            'bpmn:EndEvent': 'OUTPUT',
        };

        if (Object.keys(typeElement).includes(type)) {
            return typeElement[type]; 
        } else {
            return ""; 
        }
    }
}

export default Convert;