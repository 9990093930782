export default class CustomContextPad {
  constructor(contextPad, elementRegistry, modeling, connect, dragging, eventBus) {
    contextPad.registerProvider(this);
    this._elementRegistry = elementRegistry;
    this._modeling = modeling;
    this._connect = connect;
    this._dragging = dragging;
    this._eventBus = eventBus;
  }

  getContextPadEntries(element) {
    return (entries) => {
      // Remove as entradas indesejadas
      delete entries["append.end-event"];
      delete entries["append.subprocess"];
      delete entries["append.gateway"];
      delete entries["append.intermediate-event"];
      delete entries['append.change-element'];
      delete entries['append.text-annotation'];
      delete entries['append.append-task'];
      delete entries['replace'];
      delete entries['delete'];
      

      entries['delete-processo'] = {
        group: 'model', // Grupo onde a entrada será exibida
        className: 'bpmn-icon-trash', // Classe CSS para o ícone (certifique-se de definir isso no CSS)
        title: 'Deletar', // Texto exibido ao passar o mouse sobre a entrada
        action: {
          click: (event, element) => {
            // Adicione aqui a lógica personalizada que você deseja executar
            this.handleCustomAction(element);
          }
        }
      };

      this._eventBus.on('canvas.viewbox.changed', () => {
        // go ahead and re-position popup menu
        console.log("Change View");
      })

      return entries;
    };
  }

handleCustomAction(element) {
    // Verifica se o elemento está no registry
    const existingElement = this._elementRegistry.get(element.id);
    if (existingElement) {
      // Remove o elemento do diagrama
      this._modeling.removeElements([existingElement]);

      // Armazena o ID do elemento no localStorage
      const deleteItems = localStorage.getItem('removeItems');
      let propertiesDataArray = [];

      // Verifica se já existem itens no localStorage
      if (deleteItems) {
        try {
          // Tenta fazer o parsing dos itens armazenados
          propertiesDataArray = JSON.parse(deleteItems);

          // Verifica se o parsing resultou em um array
          if (!Array.isArray(propertiesDataArray)) {
            console.error('Os dados armazenados não são um array. Reinicializando.');
            propertiesDataArray = [];
          }
        } catch (error) {
          console.error('Erro ao fazer parsing dos dados do localStorage:', error);
          propertiesDataArray = [];
        }
      }

      // Adiciona o elemento ao array
      propertiesDataArray.push(element);

      // Atualiza o localStorage com o array atualizado
      localStorage.setItem('removeItems', JSON.stringify(propertiesDataArray));

    } else {
      console.error('Elemento não encontrado no registry:', element.id);
    }
  }

  saveConnection(start, end) {
    // Aqui você pode salvar a conexão em um banco de dados, enviar para uma API, etc.

    console.log('Salvando conexão...', {
      startElement: start,
      endElement: end
    });
  }

}

CustomContextPad.$inject = ["contextPad", "elementRegistry", "modeling",  "connect", "dragging", "eventBus"];
