import api from './api';
import TokenService from './token.service';

const LocationService = { 
    getLocations: async () => {
        try {
            const response = await api
            .get('/adm/local/', {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },
    newLocal: async (name: string, internal_code: string, zip_code: string, address: string, number: number, city: string, state: string) => {
        try {
          const response = await api
          .post('/adm/local/', {
            name, 
            internal_code,
            zip_code,
            address,
            number,
            city,
            state
          }, {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
          });
  
          if (response.status === 200) {
            return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    Location: async (local_id) => {
      try {
        const response = await api
        .get(`/adm/local/${local_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    EditStatus: async (local_id, activate: boolean) => {
      try {
        const response = await api
        .put(`/adm/local/${local_id}/visibility/`, {
          activate
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response.status === 200) {
          return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    EditLocation:  async (local_id, internal_code: string, zip_code: string, address: string, number: number, city: string, state: string) => {
      try {
        const response = await api
        .put(`/adm/local/${local_id}/`, {
          internal_code,
          state,
          zip_code,
          city,
          address,
          number  
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response.status === 200) {
          return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    }
}

export default LocationService;