import React, { useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import MeasurementService from '../../../../services/measurement';

const NewMeasure = ({ onMeasureCreated }) => {
  const [show, setShow] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [name, setName] =  useState('');
  const [symbol, setSymbol] =  useState('');

  const handleNewMeasuare = async () => {
    let valid = true;
    let erros = {};
    
    if (!symbol.trim()) {
        erros.symbol = 'O campo "símbolo" é obrigatório';
        valid = false;
    } 

    if (!name.trim()) {
        erros.name = 'O nome é obrigatório';
        valid = false;
    }

    if (valid) {
      try {
        const response = await MeasurementService.newMeasurement (
            name,
            symbol
        );
        if (response.status === 200) {
            setErros({});
            setAlert(true);
            setName('');
            setSymbol('');
        } else {
            erros.submit = response.message;
            setErros(erros);
            setAlert(true);
        }
      } catch (error) {
          return error;
      }
    } else {
      setErros(erros);
    }

    onMeasureCreated();
  };

  const handleClose = () => {
    setErros({});
    setShow(false);
    setName('');
    setSymbol('');
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Nova Unidade
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nova unidade de medida</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
        <div>
              <CustomInput
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
        </div>
        <div>
              <CustomInput
                  label="Símbolo"
                  type="text"
                  placeholder="°C"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
              />
              {erros.symbol && <span className='texterror'>{erros.symbol}</span>}
        </div>
        <div>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local criado com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleNewMeasuare}>
            Criar unidade
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewMeasure;