import React, { useState } from 'react';
import { Link,useLocation} from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import "./index.scss";
import HomeIcon from '../icons/home';
import UserIcon from '../icons/user';
import LocationIcon from '../icons/location';
import MeasureIcon from '../icons/measure';
import FrequencyIcon from '../icons/frequency';
import ExpandIcon from '../icons/expand';
import MinusIcon from '../icons/minus';
import AllocateIcon from '../icons/allocate';
import BMPIcon from '../icons/bmp';

const Sidebar = () => {
  const url = useLocation();
  const [show, setShow] = useState(false);

  const handleExpand = () => {
    if(show) setShow(false);
    else setShow(true);
  }
  
  return (
    <Nav className={`d-none d-md-block bg-light sidebar ${show ? 'container_sameicon' : ''}`}
         activeKey="/"
         style={{ height: '100vh' }}>
      <span className={`cta_sidebar ${show ? 'cta_expand' : 'cta_minus'}`} onClick={handleExpand}> {show ? <ExpandIcon /> : <MinusIcon />} </span>
      <div className="sidebar-sticky"></div>
        <Nav.Item>
            <Nav.Link as={Link} to="/" className={url.pathname === '/' ? 'active_item_menu' : ''}><HomeIcon /> <span  className={show ? 'sameicon' : ''}>Página Inicial</span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/usuarios" className={url.pathname.startsWith('/usuarios') ? 'active_item_menu' : ''}><UserIcon /> <span  className={show ? 'sameicon' : ''}> Usuários</span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/locais" className={url.pathname.startsWith('/locais') ? 'active_item_menu' : ''}><LocationIcon /> <span  className={show ? 'sameicon' : ''}> Locais</span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/alocacoes" className={url.pathname.startsWith('/alocacoes') ? 'active_item_menu' : ''}><AllocateIcon /> <span  className={show ? 'sameicon' : ''}> Alocações </span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/unidades" className={url.pathname.startsWith('/unidades') ? 'active_item_menu' : ''}><MeasureIcon /> <span  className={show ? 'sameicon' : ''}> Unidades de medida</span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/frequencias" className={url.pathname.startsWith('/frequencias') ? 'active_item_menu' : ''}><FrequencyIcon /> <span  className={show ? 'sameicon' : ''}> Frequências de coleta </span></Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={Link} to="/processos" className={url.pathname.startsWith('/processos') ? 'active_item_menu' : ''}><BMPIcon /> <span  className={show ? 'sameicon' : ''}> Processos </span></Nav.Link>
        </Nav.Item>
    </Nav>
  );
};

export default Sidebar;