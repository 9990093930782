import React, {  useState, useEffect} from 'react';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import {Badge, Container, Row} from 'react-bootstrap';
import CustomInput from '../../components/input';
import LocationService from '../../services/location';
import DataTable from 'react-data-table-component';
import Breadcrumbs from '../../components/breadcrumbs';
import EditIcon from '../../components/icons/edit';
import NewLocation from './component/new_location';

const LocationsList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [dataLocations, setDataLocations] = useState([]);
  const [vLocation, setvLocation] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [roleUser, setRoleUser] = useState('');

  const filteredItems = dataLocations.filter(item => 
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) || 
    (item.internal_code && item.internal_code.toLowerCase().includes(filterText.toLowerCase())) || 
    (item.city && item.city.toLowerCase().includes(filterText.toLowerCase()))
  );


  const handleLocationCreated = () => {
    setvLocation(prev => !prev);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

useEffect(() => {
  if (currentUser && roleUser) {
    if (roleUser === "" || !roleUser.trim()) {
      navigate('/login');
    }
  } else if (!currentUser) {
    navigate('/login');
  } 
}, [roleUser, currentUser, navigate]);

useEffect(() => {
  const fetchLocations = async () => {
    const location = await LocationService.getLocations();
    if (location) {
      setDataLocations(location.data.data)
    } else {
      console.log('Não foi possível obter os usuários.');
    }
  };

  fetchLocations();
}, [vLocation]);

const EditLocation = ({ id }) => (
  <Link to={`/locais/${id}`}>
    <EditIcon />
  </Link>
);

const ActiveLocation = ({ active }) => (
  <Badge bg={active ? "success" : "danger"}>{active ? "Ativo" : "Desativado"}</Badge>
);

const columns = [
  {
    name: 'ID',
    selector: row => row.local_id,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: row => row.name,
    sortable: true,
  },
  {
    name: 'Código Interno',
    selector: row => row.internal_code,
  },
  {
    name: 'Cidade',
    selector: row => row.city,
    sortable: true,
  },
  {
    name: 'Estado',
    selector: row => row.state,
    sortable: true,
  },
  {
    name: 'Situação',
    selector: row => row.active,
    cell: row => <ActiveLocation active={row.active} />,
    sortable: true,
    center: "true",
  },
  {
    selector: row => row.user_id,
    cell: row => <EditLocation id={row.local_id} />,
    width: "50px"
  },
];

const paginationComponentOptions = {
  rowsPerPageText: 'Locais por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

    return (
      <div>
        <Container className='container-custom-fluid'>
            <Row>
                <header className='header'>
                    <div>
                        <Breadcrumbs />
                        <h1>Locais</h1>
                    </div>
                    <div>
                      {roleUser.trim() && roleUser === "MASTER" && (
                          <NewLocation onLocationCreated={handleLocationCreated} />
                      )}
                    </div>
                </header>
                <section>
                    <div className='container-panel'>
                      <div className='container-panel-input'>
                          <CustomInput
                              label="Pesquisa"
                              type="text"
                              placeholder="Digite o nome, cidade ou código interno que deseja pesquisar"
                              value= {filterText}
                              onChange= {(e) => setFilterText(e.target.value)}
                          />
                        </div>
                    </div>
                </section>

                <section>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      responsive
                      striped
                      paginationComponentOptions={paginationComponentOptions}
                      noDataComponent={<div>Nenhum local disponível ou cadastrado</div>}
                    />
                </section>
            </Row>
        </Container>
      </div>
    );
  };
  
  export default LocationsList;