import React, { useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import Validate from '../../../../utils/validate';
import FrequencyService from '../../../../services/frequency';

const NewFrequency = ({ onFrequencyCreated }) => {
  const [show, setShow] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [name, setName] =  useState('');
  const [minutes, setMinutes] =  useState('');

  const handleNewFrequency = async () => {
    let valid = true;
    let erros = {};
    
    if (!minutes.trim()) {
        erros.minutes = 'O campo "minutos" é obrigatório';
        valid = false;
    } else if (!Validate.validateisPositiveInteger(minutes)){
        erros.minutes = 'Por favor, insira apenas números inteiros positivos.';
        valid = false;
    }

    if (!name.trim()) {
        erros.name = 'O nome é obrigatório';
        valid = false;
    }

    if (valid) {
      try {
        const response = await FrequencyService.newFrequency (
            name,
            parseInt(minutes)
        );
        if (response.status === 200) {
            setErros({});
            setAlert(true);
            setName('');
            setMinutes('');
        } else {
            erros.submit = response.message;
            setErros(erros);
            setAlert(true);
        }
      } catch (error) {
          return error;
      }
    } else {
      setErros(erros);
    }

    onFrequencyCreated();
  };

  const handleClose = () => {
    setErros({});
    setShow(false);
    setName('');
    setMinutes('');
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Nova Frequência
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Frequência</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
        <div>
              <CustomInput
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
        </div>
        <div>
              <CustomInput
                  label="Minutos"
                  type="number"
                  placeholder="Minutos somente números"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
              />
              {erros.minutes && <span className='texterror'>{erros.minutes}</span>}
        </div>
        <div>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local criado com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleNewFrequency}>
            Criar frequência
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewFrequency;