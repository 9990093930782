import "./index.scss";

const MeasureIcon = () => {
    return (
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-ruler-measure-2">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 19.875c0 .621 -.512 1.125 -1.143 1.125h-5.714a1.134 1.134 0 0 1 -1.143 -1.125v-15.875a1 1 0 0 1 1 -1h5.857c.631 0 1.143 .504 1.143 1.125z" />
                <path d="M12 9h-2" />
                <path d="M12 6h-3" />
                <path d="M12 12h-3" />
                <path d="M12 18h-3" />
                <path d="M12 15h-2" />
                <path d="M21 3h-4" />
                <path d="M19 3v18" />
                <path d="M21 21h-4" />
            </svg>
        </div>
    );
};

export default MeasureIcon;
