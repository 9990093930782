const Validate = {
    validateEmail: (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    },
    validatePassword: (password) => {
        const errorPassword = [];
        if (password.length < 8) {
          errorPassword.push("A senha precisa ter 8 caracteres ou mais.");
        }
        if (!/[A-Z]/.test(password)) {
          errorPassword.push("A senha precisa ter ao menos um caractere maiúsculo.");
        }
        if (!/[a-z]/.test(password)) {
          errorPassword.push("A senha precisa ter ao menos um caractere minúsculo.");
        }
        if (!/[0-9]/.test(password)) {
          errorPassword.push("A senha precisa ter ao menos um número.");
        }
        if (!/[@!$#]/.test(password)) {
          errorPassword.push("A senha precisa ter ao menos um caractere especial (@, !, $, #).");
        }
        return errorPassword;
    },
    
    validateDate: (date) => {
      const regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return regex.test(date);
    },
    
    validateisPositiveInteger(value) {
      return /^\d+$/.test(value);
    }
}

export default Validate;