import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import "./index.scss";

interface CustomInputProps {
  label: string;
  type: string;
  placeholder?: string;
  value: string;
  readOnly?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  type,
  placeholder,
  value,
  readOnly,
  onChange,
  ...props
}) => {
  return (
    <Form.Group controlId={`formBasic${label}`} className='input'>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
    </Form.Group>
  );
};

export default CustomInput;