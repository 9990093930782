import React from 'react';
import { Form } from 'react-bootstrap';

const TextArea = ({ label, value, onChange, placeholder, rows }) => {
  return (
    <Form.Group controlId="formTextarea">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={rows || 3}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Form.Group>
  );
};

export default TextArea;