import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './components/navbar';
import Sidebar from './components/sidebar/sidebar';
import './App.scss';

function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const shouldDisplay = location.pathname !== '/login' && location.pathname !== '/esquecisenha';

  return (
    <>
      {shouldDisplay && <Navbar />}
      <div className="container-fluid container-root">
        {shouldDisplay && <Sidebar />}
        <div className='container-custom-fluid'>
          {children}
        </div>
      </div>
    </>
  );
}

export default Layout;
