import "./index.scss";

const BMPIcon = () => {
    return (
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-hierarchy-2">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 3h4v4h-4z" />
                <path d="M3 17h4v4h-4z" />
                <path d="M17 17h4v4h-4z" />
                <path d="M7 17l5 -4l5 4" />
                <path d="M12 7l0 6" />
            </svg>
        </div>
    );
};

export default BMPIcon;