import axios from "axios";

const api = axios.create({
    baseURL: 'https://mesbackend.intelligentsystems.com.br',
    withCredentials: true,  // Isso garante que os cookies sejam enviados
});

api.interceptors.request.use(config => { 
  return config;
});

export default api;