import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

const Home = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');


  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);


  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
              <h1>Bem-vindo!</h1>
          </div>
        </header>
      </Row>
    </Container>
  );
};

export default Home;