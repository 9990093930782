import { Navbar, Nav} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import "./index.scss";
import AuthService from '../../services/auth.service';


const NavBar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        const fetchLogin = async () => {
            try {
                const logout = await AuthService.logout();
                if (logout === 200) {
                    navigate("/login");
                } 
            } catch (error) {
            console.error("Failed to fetch role:", error);
            }
        };

        fetchLogin();
    }

    return (
        <Navbar className="container_navbar" fixed="top">
            <Link to="/">
                <Navbar.Brand><img src="/image/logo_navbar.png" alt="Logo Alpargatas" /></Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className='menu'>
                    <span onClick={handleLogout}>Sair</span>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
  };
  
  export default NavBar;