import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../components/breadcrumbs';
import LocationService from '../../services/location';
import CustomSelect from '../../components/select';
import UserService from '../../services/user';
import ExchangeIcon from '../../components/icons/exchange';
import "./index.scss";
import AllocateService from '../../services/allocate';

const AllocateList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataLocations, setDataLocations] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [alocation, setaLocation] = useState([]);
  const [aUser, setaUser] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      const location = await LocationService.getLocations();
      if (location) {
        let localSelect = location.data.data;
        let transformedLocations = localSelect.map(location => ({
          label: location.name,
          value: location.local_id,
        }));
        setDataLocations(transformedLocations)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };

    const fetchUsers = async () => {
      const user = await UserService.getUsers();
      if (user) {
        let userSelect = user.data.data
        let transformedUsers = userSelect.map(user => ({
          label: user.name,
          value: user.user_id,
        }));
        setDataUsers(transformedUsers)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };
  
    fetchUsers();
    fetchLocations();
  }, [navigate]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  const handleAllocate = async () => {
    let erros = {};
    let locals = alocation.map(item => item.value);

    try {
      const response = await AllocateService.newAllocate(
          parseInt(aUser.value),
          locals
      );
      if (response.status === 200) {
        setErros({});
        setAlert(true);
      } else {
        erros.submit = response.message;
      }
    } catch (error) {
      erros.submit = error.message.data;
    }
    
    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }
  };

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Alocações</h1>
          </div>
        </header>
        <section>
        <div className='container-panel'>
            <div className='container-panel-select'>
              <CustomSelect 
                label="Usuário" 
                options={dataUsers} 
                value={aUser ? aUser.value : ''} 
                onChange={setaUser} 
              />

              <div className="input_p container_exchange_icon"> <ExchangeIcon /> </div>

              <CustomSelect 
                label="Local" 
                options={dataLocations} 
                value={alocation.map(option => option.value)} 
                onChange={setaLocation} 
                isMultic={true}
              />

            </div>

            <div className='container-alerts'>
              {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local alterado com sucesso!</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
            </div>

            <div className='container-panel-button'>
              <Button variant="primary" onClick={handleAllocate}>
                  Alocar usuário
              </Button>
            </div>
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default AllocateList;