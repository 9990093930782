import "./index.scss";

const FrequencyIcon = () => {
    return (
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-clock-play">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 7v5l2 2" />
                <path d="M17 22l5 -3l-5 -3z" />
                <path d="M13.017 20.943a9 9 0 1 1 7.831 -7.292" />
            </svg>
        </div>
    );
};

export default FrequencyIcon;