import Select from 'react-select';
import "./index.scss";

function CustomInput({ options, onChange, value, label, isMultic = false }) {
  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <div className='select'>
      {label && <label>{label}</label>}
      <Select
        isMulti={isMultic}
        value={isMultic
          ? options.filter(option => value && value.includes(option.value))
          : options.find(option => option.value === value)}
        onChange={handleChange}
        options={options}
        placeholder="Digite para buscar..."
        isSearchable={true}
      />
    </div>
  );
}

export default CustomInput;
