import React, { useEffect } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Button} from 'react-bootstrap';
import CustomInput from '../../../components/input';

const Profile = () => {
    const navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
  
    useEffect(() => {
      if (!currentUser) {
        //navigate('/login');
      }
    }, [currentUser, navigate]);
  
    return (
      <div>
        <Container>
            <Row>
                <header className='header'>
                    <div>
                        <h1>Perfil</h1>
                    </div>
                </header>
                <section>
                    <div className='container-panel'>
                    <h2>Dados gerais</h2>
                        <div className='container-panel-input'>
                            <CustomInput
                                label="Nome"
                                type="text"
                                placeholder=""
                                value=""
                                //onChange=""
                            />
                            <CustomInput
                                label="E-mail"
                                type="text"
                                placeholder=""
                                value=""
                                //onChange=""
                        />
                        </div>
                        <div className='container-panel-date'>
                            <CustomInput
                                label="Data de nascimento"
                                type="date"
                                placeholder=""
                                value=""
                                //onChange=""
                            />
                            <CustomInput
                                label="Data de admissão"
                                type="date"
                                placeholder=""
                                value=""
                                readOnly={true}
                                //onChange=""
                        />
                        </div>
                    </div>
                    <div className='container-panel-button'>
                      <Button variant="primary">
                          Salvar alterações
                      </Button>
                    </div>
                </section>
            </Row>
        </Container>
      </div>
    );
  };
  
  export default Profile;