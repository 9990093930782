import api from './api';
import TokenService from './token.service';

const FrequencyService = {
    getFrequencys: async () => {
        try {
            const response = await api
            .get('/adm/frequency/', {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },
    newFrequency: async (name: string, minutes: number) => {
      try {
        const response = await api
        .post('/adm/frequency/', {
          name,
          minutes
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response.status === 200) {
          return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  EditFrequency: async (frequency_id, activate: boolean) => {
    try {
      const response = await api
      .put(`/adm/frequency/${frequency_id}/`, {
        activate
      }, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
      });

      if (response.status === 200) {
        return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
  Frequency: async (frequency_id) => {
    try {
      const response = await api
      .get(`/adm/frequency/${frequency_id}/`, {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
}

export default FrequencyService;
