import api from './api';
import TokenService from './token.service';

const UserService = {
    getUsers: async () => {
        try {
            const response = await api
            .get('/accounts/users/', {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },

    newUser: async (name: string, email: string, password1: string, password2) => {
      try {
        const response = await api
        .post('/accounts/create/', {
          name,
          email,
          password1,
          password2
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response.data) {
          return response.data;
        }
      } catch (error) {
        return error.response?.data;
      }
    },
    User: async (user_id) => {
      try {
          const response = await api
          .get(`/accounts/${user_id}/profile/`, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });
  
          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    editUser: async (user_id, name: string, email: string, birth_date: string, admission_date: string) => {
      try {
        const response = await api
        .put(`/accounts/${user_id}/profile/`, {
          name,
          email,
          birth_date,
          admission_date
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response.status === 200) {
          return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    editUserRole: async  (user_id, role: string) => {
      try {
        const response = await api
        .post(`/accounts/${user_id}/roles/`, {
            "roles": [
              role
            ]
        }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
        });

        if (response) {
          return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
};

export default UserService;